<template>
  <v-dialog :value="value" @input="$emit('input')" :max-width="maxWidth">
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <strong>{{ message }}</strong>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <slot name="actions">
          <slot name="action-cancel">
            <v-btn text @click="$emit('cancel')">{{ cancelText }}</v-btn>
          </slot>

          <slot name="action-confirm">
            <v-btn color="primary" text @click="$emit('confirm')">
              {{ confirmText }}
            </v-btn>
          </slot>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    confirmText: {
      type: String,
      default: 'Confirm'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    maxWidth: {
      type: Number,
      default: 290
    }
  }
}
</script>

<style></style>
